import 'bootstrap';
import QRCode from 'qrcode';
import countdown from 'countdown';

// Theme
import masonryGrid from './components/masonry-grid';
import stickyNavbar from './components/sticky-navbar';
import navbarSearch from './components/navbar-search';
import passwordVisibilityToggle from './components/password-visibility-toggle';
import fileDropArea from './components/file-drop-area';
import formValidation from './components/form-validation';
import inputFormatter from './components/input-formatter';
import scrollTopButton from './components/scroll-top-button';
import tooltip from './components/tooltip';
import popover from './components/popover';
import toast from './components/toast';
import gallery from './components/gallery';
import subscriptionForm from './components/subscription-form';
import labelUpdate from './components/form-label-update';
import carousel from './components/carousel';
import charts from './components/charts';
// import countdown from './components/countdown';
import datePicker from './components/date-picker';
import radioTab from './components/radio-tab';
import parallax from './components/parallax';
import priceSwitch from './components/price-switch';
import productGallery from './components/product-gallery';
import videoBtn from './components/btn-video';
import rangeSlider from './components/range-slider';
import viewSwitcher from './components/view-switcher';
import checkboxToggleClass from './components/checkbox-toggle-class';
import masterCheckbox from './components/master-checkbox';




// Init all QR codes on page
$( document ).on('turbolinks:load', function(){

    $('img.qrcode').each(function(){
        const data = $(this).data('url');
        const width = $(this).data('width') || 200;
        var img = $(this)[0];
        var opts = {
            type: 'image/png',
            quality: 0.3,
            width: width,
            margin: 0,
        };
        QRCode.toDataURL(data, opts, function (err, url) {
            if (err) throw err;
            img.src = url;
        });
    });

    // Make toast hiding
    $('body').on('click', '.btn-close',function(){
        $(this).closest('.toast').toast('hide');
    });

    window.countdown = countdown;

    if(!!$('#countdown')) {
        setInterval(() => $('#countdown').html(countdown( Date.parse(window.gon.paidTill), null, countdown.HOURS|countdown.MINUTES|countdown.SECONDS ).toString()), 1000);
    }

    // Make retina images
    if(window.devicePixelRatio > 1){
        $('img[data-interchange]').each(function() {
            var retina = function() {
                $(this).unbind('load').width($(this).width()).attr('src', $(this).attr("data-interchange"));
            };
            if( this.complete ) {
                retina.apply(this)
            } else {
                $(this).on('load', retina)
            }
        });
    }


});

window.showWarning = function(message, actionLink) {
    let html = `<div class="toast top-0 end-0 show position-absolute m-3" role="alert" aria-live="assertive" aria-atomic="true" style="z-index: 1050">
        <div class="toast-header bg-warning text-white">
          <i class="ai-alert-triangle me-2"></i>
          <span class="me-auto">Attention</span>
          <button type="button" class="btn-close btn-close-white ms-2 mb-1" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body text-warning">
            <div>${message}</div>
            <div class="mt-2 pt-2">
              <a class="btn btn-warning btn-sm" href="${actionLink}">Go to issues</a>
            </div>
        </div>
        
      </div>`;
    document.body.insertAdjacentHTML('beforeend', html);
};