import consumer from "./consumer";

if(window.parking && window.parking.id) {
    consumer.subscriptions.create({channel: 'IssueChannel', room: window.parking.id}, {
        received(data) {

            // Change issues counter
            if(data && typeof(data.counter) != 'undefined') {
                let counter = document.getElementById('slot-issues-counter');
                if(parseInt(data.counter) > 0) {
                    counter.innerText = data.counter;
                    counter.classList.remove('d-none');
                } else {
                    // Hide
                    counter.innerText = '0';
                    counter.classList.add('d-none');
                }
            }

            // If issue message present, display it
            if(data && typeof(data.title) != 'undefined') {
                showWarning(data.title, `/parkings/${window.parking.id}/slot_issues`);
            }

        }
    });
}



